<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card :title="orderTitle">
          <b-card-text class="text-primary">
            <div class="inbox-item">

              <p>Port: <code>{{ ss.port }}</code></p>
              <p>密码: <code>{{ ss.passwd }}</code></p>
              <p>V2rayUUID: <code>{{ ss.v2ray_uuid }}</code></p>

              <p>流量重置时间: <code>{{ lastResetTrafficAt }}</code></p>
              <p>下次流量重置时间: <code>{{ nextResetTrafficAt }}</code></p>

            </div>

          </b-card-text></b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <OrderStatisticsWidget :data="statisticsItems" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="4"
        md="6"
      >

        <b-card title="订阅配置">
          <b-card-text class="text-primary">
            <div class="inbox-item">

              <b-alert
                variant="primary"
                show
              >
                <h4 class="alert-heading">
                  注意
                </h4>
                <div class="alert-body">
                  <p><span>请勿泄露订阅连接。尽量使用Clash客户端。</span></p>
                  <p><span>Windows/Mac/Android 请使用 Clash 客户端 </span></p>
                  <p><span>iOS可以使用 Shadowrocket, Shadowrocket 也可以使用 Clash 的订阅链接 </span></p>
                </div>
              </b-alert>

              <h5>订阅链接</h5>

              <p>请查阅<a href="https://doc.loxcloud.com">文档</a>以使用订阅链接。</p>

              <p>Clash: <code>{{ sub.clash }}</code><b-button
                v-clipboard:copy="sub.clash"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="sm"
                variant="outline-success"
              >
                复制
              </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="outline-success"
                  :href="clashOpen"
                >
                  打开Clash
                </b-button>
              </p>
              <p>Surge: <code>{{ sub.surge }}</code><b-button
                v-clipboard:copy="sub.surge"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="sm"
                variant="outline-success"
              >
                复制
              </b-button></p>

              <p>QuanX: <code>{{ sub.quan_x }}</code><b-button
                v-clipboard:copy="sub.quan_x"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="sm"
                variant="outline-success"
              >
                复制
              </b-button></p>

              <p>V2ray: <code>{{ sub.v2ray }}</code>     <b-button
                v-clipboard:copy="sub.v2ray"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                size="sm"
                variant="outline-success"
              >
                复制
              </b-button> </p>

            </div>

          </b-card-text>

        </b-card>

        <b-card title="续费">
          <b-card-text class="text-primary">
            <div class="inbox-item">

              <h6>当前套餐 {{ plan.name }}</h6>

              <p>流量: <b>{{ plan.traffic }}G</b></p>
              <p>续费价格: -月<code>{{ plan.month_price }}</code>
                -半年<code>{{ plan.six_month_price }}</code>
                -年<code>{{ plan.year_price }}</code></p>

              <h6>选择续费周期</h6>
              <p>
                <b-form-select
                  v-model="month"
                  :options="monthOpts"
                />
              </p>

              <p>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="renew"
                >
                  续费
                </b-button>
              </p>

            </div>

          </b-card-text>

        </b-card>

        <b-card title="AppleID">
          <b-card-text class="text-primary">
            <div class="inbox-item">

              <b-alert
                variant="primary"
                show
              >
                <h4 class="alert-heading">
                  注意
                </h4>
                <div class="alert-body">
                  <span>ID请勿开启二步验证。ID 可用于下载Shadowrocket.如遇到ID不可用，请联系我们进行解锁。</span>
                </div>
              </b-alert>

              <p
                v-for="aid in appleids"
                :key="aid.Email"
              >
                Email: <code>{{ aid.Email }}</code> Password: <code>{{ aid.Password }}</code>
              </p>

            </div>

          </b-card-text>

        </b-card>

        <b-card title="工单支持">
          <b-card-text class="text-primary">
            <div class="inbox-item">
              <NewTicket
                :body="ticket.body"
                :title="ticket.title"
                :order-i-d="order.id"
              />
            </div>

          </b-card-text>

        </b-card>

      </b-col>
      <b-col
        xl="8"
        md="6"
      >

        <b-card title="Nodes">
          <b-card-text>
            <div class="inbox-item">
              <p>节点&监控</p>
              <b-alert
                variant="info"
                show
              >
                <h4 class="alert-heading">
                  说明
                </h4>
                <div class="alert-body">
                  <span>仅展示部分节点，请通过订阅连接配置。部分中转入口这里不显示。</span>
                </div>
              </b-alert>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-row>
                    <b-col
                      v-for="node in nodes"
                      :key="node.Code"
                      md="6"
                      lg="4"
                    >
                      <b-card
                        :title="node.Name"
                        :sub-title="node.Code"
                        class="mb-4"
                      >
                        <b-card-text>
                          <p>Server: <code>{{ node.Server }}</code></p>
                          <p>流量比例: <code>{{ node.TrafficRate }}</code></p>
                          <p>实时 ↑ <b-badge
                            variant="success"
                            class="badge-glow"
                          >
                            {{ bytesToSize(node.bytesSent) }}
                          </b-badge>  ↓ <b-badge
                            variant="info"
                            class="badge-glow"
                          >
                            {{ bytesToSize(node.bytesRecv) }}
                          </b-badge>  </p>
                          <p> 历史: ↑    <b-badge variant="success">
                            {{ bytesToSize(node.bytesSentTotal) }}
                          </b-badge>  ↓  <b-badge variant="info">
                            {{ bytesToSize(node.bytesRecvTotal) }}
                          </b-badge>  </p>
                          <p>Load  <b-progress
                            :value="node.load1"
                            :max="node.cpu_count"
                            :variant="getVariant(fixNodeLoad(node))"
                          /> </p>
                        </b-card-text>
                      </b-card>   </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <NodeList
                v-if="false"
                :data="nodesArr"
              />
            </div>
          </b-card-text>
          <b-card-text v-if="false">
            <div class="inbox-item">
              <app-collapse>
                <app-collapse-item
                  v-for="node in nodes"
                  :key="node.Code"
                  :title="node.Name"
                  :name="node.Code"
                >
                  <p>Server: <code>{{ node.Server }}</code></p>
                  <p>流量比例: <code>{{ node.TrafficRate }}</code></p>
                </app-collapse-item>
              </app-collapse>

            </div>

          </b-card-text>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// import Vue from 'vue'
import { bytesToSize } from '@/utils/flow'
import {
  getMonthInfo, notify, throwError, throwRes,
} from '@/utils/utils'
import { formatDate, convertTimestamp } from '@/utils/format-date'
import useHttp from '@/http/useHttp'
import { ApiSrvClient } from '@/proto/api_grpc_web_pb'
import { MonitorStreamRequest, PingRequest } from '@/proto/api_pb'
import NodeList from '@/components/shadowx/NodeList.vue'
import Ripple from 'vue-ripple-directive'
import OrderStatisticsWidget from '@/components/shadowx/OrderStatisticsWidget.vue'
import NewTicket from '@/components/site/NewTicket.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow, BCol, BCard, BCardText, BAlert, BFormSelect, BButton, BBadge, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BAlert, BButton, BCardText, BBadge, BFormSelect, BProgress, OrderStatisticsWidget, AppCollapse, AppCollapseItem, NodeList, NewTicket,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      order: {},
      ss: {},
      ids: {},
      nodesArr: [],
      nodes: {},
      plan: {},
      sub: {},

      clashOpen: 'clash://install-config?url=',

      ticket: {
        title: '',
        body: '',
      },
      appleids: [],

      monthOpts: [],
      activeNodes: [],

      month: 1,

      lastResetTrafficAt: '',
      nextResetTrafficeAt: '',

      congratulations: {
        name: 'John',
        saleToday: '48900',
      },

      orderTitle: '',

      ws: {},
      monitorWsAddr: 'wss://api.loxcloud.com/v1/ws/monitor',

      statisticsItems: [
        {
          icon: 'ActivityIcon',
          color: 'light-primary',
          title: '-',
          subtitle: '套餐流量',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-info',
          title: '-',
          subtitle: '已用流量',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ChevronsDownIcon',
          color: 'light-success',
          title: '-',
          subtitle: '剩余流量',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'ChevronRightIcon',
          color: 'light-danger',
          title: '-',
          subtitle: '过期时间',
          customClass: '',
        },
      ],

    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.monthOpts = this.getMonthInfo()
    this.getData()
    this.initWebsocket()
    // this.streamClient()
  },
  destroyed() {
    this.ws.close()
  },
  methods: {
    async getData(arg) {
      await useHttp.getShadowxOrderInfo(this.id, arg).then(res => {
        // this.data = res.data.data
        const { data } = res.data
        this.order = data.order
        this.appleids = data.apple_id
        this.ss = data.ss
        this.sub = data.sub

        this.clashOpen += encodeURIComponent(this.sub.clash)

        const { nodes } = data
        data.nodes.map(item => {
          const node = item
          node.bytesSentTotal = 0
          if (item.MonitorEnable) {
            this.nodes[item.Code] = node
          }
          return item
        })
        this.nodesArr = nodes
        for (let i = 0; i < nodes.length; i += 1) {
          this.ids[nodes[i].Code] = i
          this.nodesArr[i].bytesTotal = 0
        }
        // console.log(this.nodes)
      }).catch(error => {
        notify('Error', error, 'danger')
      })

      this.statisticsItems[0].title = bytesToSize(this.ss.transfer_enable)
      this.statisticsItems[1].title = bytesToSize(this.ss.u + this.ss.d)
      this.statisticsItems[2].title = bytesToSize(this.ss.transfer_enable - this.ss.u - this.ss.d)
      this.statisticsItems[3].title = this.formatDate(this.order.expire_at)
      const orderID = this.order.id
      this.orderTitle = `订单号 #${orderID}`

      this.lastResetTrafficAt = convertTimestamp(this.ss.last_reset_tra_time)
      this.nextResetTrafficAt = convertTimestamp(this.ss.last_reset_tra_time + 3600 * 24 * 30)

      useHttp.getShadowxPlanInfo(this.order.plan_id).then(res => {
        this.plan = res.data.data
      }).catch(error => {
        notify('Error', error, 'danger')
      })
    },
    handleNodeChange() {

    },
    streamClient() {
      const addr = 'https://apiserver.loxcloud.com'
      // console.log(opts)
      const apiService = new ApiSrvClient(addr, null, null)

      const metadata = {
      }
      const streamRequest = new MonitorStreamRequest()

      const pingRequest = new PingRequest()
      pingRequest.message = 'ping'
      apiService.ping(pingRequest, metadata, (err, response) => {
        if (err) {
          // console.log('ping error', err)
          throwError(err)
        }
        const message = response.getMessage()
        // console.log(message)
        throwRes(message)
        // notify('debug', message)
      })

      const stream = apiService.monitorStream(streamRequest, metadata)
      stream.on('data', response => {
        // console.log(response.getMessage())
        const body = response.getBody()
        throwRes(body)
      })
    },
    renew() {
      useHttp.getShadowxOrderRenew(this.id, {
        month: this.month,
      }).then(res => {
        throwRes(res)
        notify('OK', '续费成功')
        // refresh
        this.getData({})
      }).catch(error => {
        throwError(error)
        notify('Error', '出错了，请确认余额是否足够以及订单是否失效。')
      })
    },
    newTicket() {

    },
    onCopy() {
      this.notify('OK', '复制成功', 'success')
    },
    onError() {
      this.notify('Error', 'Error', 'danger')
    },
    initWebsocket() {
      const wsuri = this.monitorWsAddr
      this.ws = new WebSocket(wsuri)
      this.ws.onmessage = this.wsOnMessage
    },
    wsOnMessage(evt) {
      // console.log(evt.data)
      const data = JSON.parse(evt.data)
      const body = JSON.parse(data.Body)
      // console.log(body)
      const i = body.node_code
      const { stat } = body
      // console.log(stat)
      // console.log(this.nodes[i])
      throwRes(body)
      if (this.nodes[i] != null && this.nodes[i] !== undefined) {
        if (stat.net_speed) {
          this.nodes[i].bytesTotal = stat.net_speed.bytesRecv + stat.net_speed.bytesSent
          this.nodes[i].bytesSent = stat.net_speed.bytesSent
          // this.$set(this.nodes[i], 'bytesSent', stat.net_speed.bytesSent)
          this.nodes[i].bytesRecv = stat.net_speed.bytesRecv
          // this.$set(this.nodes[i], 'bytesRecv', stat.net_speed.bytesRecv)
        }
        this.nodes[i].bytesSentTotal = stat.net_stat.bytesSent
        this.nodes[i].bytesRecvTotal = stat.net_stat.bytesRecv
        this.nodes[i].memoryUsedPercent = stat.memory_status.usedPercent
        this.nodes[i].cpu_count = stat.cpu_count
        this.nodes[i].status = stat.net_info.status
        this.nodes[i].load1 = stat.avg_load.load1
        this.nodes[i].load5 = stat.avg_load.load5
        this.nodes[i].load15 = stat.avg_load.load15
        this.nodes[i].disk_usage_percent = stat.disk_usage.usedPercent
        this.nodes[i].cpu_percent = (stat.avg_load.load1 / stat.cpu_count) * 100
        // console.log(this.nodes[i])
        const node = this.nodes[i]
        // console.log('node update', i, node.Name, node.bytesTotal)
        this.$set(this.nodes, i, node)
      }
    },
    fixNodeLoad(node) {
      const num = ((node.load1 / node.cpu_count) * 100).toFixed(0)
      return num
      // console.log(num, parseInt(num, 1))
      // return parseInt(num, 1)
    },
    getVariant(n) {
      if (n > 90) {
        return 'danger'
      }
      if (n > 60) {
        return 'warning'
      }
      return 'success'
    },
    bytesToSize,
    getMonthInfo,
    notify,
    throwRes,
    formatDate,
    convertTimestamp,
  },
}
</script>

<style>

</style>
