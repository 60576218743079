<template>
  <div>
    <b-alert
      variant="primary"
      show
    >
      <h4 class="alert-heading">
        注意
      </h4>
      <div class="alert-body">
        <span>请确保邮箱正确且已经验证，否则可能收不到工单回复。</span>
      </div>
    </b-alert>
    <b-row>
      <b-col cols="12">
        <label for="textarea-default">标题</label>
        <b-form-input
          id="h-title"
          v-model="ticket.title"
          placeholder="..."
        />
      </b-col>

      <b-col cols="12">

        <label for="textarea-default">内容</label>
        <b-form-textarea
          id="textarea-default"
          v-model="ticket.body"
          placeholder="..."
          rows="3"
        />
      </b-col>

      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="submit"
        >
          提交
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useHttp from '@/http/useHttp'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BFormTextarea, BFormInput, BAlert,
} from 'bootstrap-vue'
import {
  notify, throwError, throwRes,
} from '@/utils/utils'

export default {
  name: 'NewTicket',
  components: {
    BRow, BCol, BButton, BFormTextarea, BFormInput, BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: () => '...',
    },
    body: {
      type: String,
      default: () => '...',
    },
    orderID: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      ticket: {
        title: '',
        body: '',
      },
    }
  },
  mounted() {
    this.ticket.title = this.title
    this.ticket.body = this.body
  },
  methods: {
    async submit() {
      useHttp.newTicket({
        title: this.ticket.title,
        body: this.ticket.body,
        OrderID: this.orderID,
      }).then(res => {
        throwRes(res)
        notify('成功', '工单提交成功', 'success')
        this.ticket.body = ''
        this.ticket.title = ''
      }).catch(err => {
        throwError(err)
        notify('错误', '提交失败', 'danger')
      })
    },
  },
}
</script>

<style scoped></style>
