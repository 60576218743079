<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-row>
          <b-col
            v-for="(k, node) in data"
            :key="k"
            md="6"
            lg="4"
          >
            <b-card
              :title="node.Name"
              :sub-title="node.Server"
              class="mb-4"
            >
              <b-card-text>
                <p>Server: <code>{{ node.Server }}</code></p>
                <p>流量比例: <code>{{ node.TrafficRate }}</code></p>
                <p>当前 Out: {{ bytesToSize(node.bytesSent) }} In: {{ bytesToSize(node.bytesRecv) }} </p>
                <p>Out: {{ bytesToSize(node.bytesSentTotal) }} In: {{ bytesToSize(node.bytesRecv) }} </p>
              </b-card-text>
            </b-card>   </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils/flow'
import { formatDate } from '@/utils/format-date'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  created() {
  },
  methods: {
    fixNum(ss) {
      const num = (((ss.u + ss.d) / ss.transfer_enable) * 100).toFixed(0)
      return parseInt(num, 1)
    },
    bytesToSize,
    formatDate,
  },
}
</script>
